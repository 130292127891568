import React, { useEffect, useState, useMemo } from 'react'
import Layout from 'layouts/en'
import Seo from 'components/EN/Seo'
import { graphql } from 'gatsby'
import PartnersForm from 'components/partnersForm/formEn'
import { Form, message, Spin } from 'sensd'
import Modal from 'components/Modal'
import { getAWSEn } from 'api/index'
import 'sensd/dist/sensd.css'
import classnames from 'classnames'
import * as styles from './index.module.less'

message.config({
  top: 128,
  duration: 5,
})

enum statusType {
  init,
  loading,
  successfully,
  error,
  repetition,
}

const partnersFormEN = (props) => {
  const { data } = props
  const { partnersFormYaml } = data
  const { seo_en, header_en, left_aside_en } = partnersFormYaml
  const [status, setStatus] = useState(statusType['init'])
  const [params, setParams] = useState({})
  const [partnersForm] = Form.useForm()
  useEffect(() => {
    /**
     * {
     *  "customerAccountId": "112705827572",
     *  "customerIdentifier": "7r9mykCt3P6"
      }
     */
    const queryParams = getAllQueryParams()
    setParams(queryParams)
  }, [])
  // 遍历参数
  function getAllQueryParams() {
    const params = new URLSearchParams(window.location.search)
    const queryParams = {}

    for (const [key, value] of params.entries()) {
      queryParams[key] = value
    }

    return queryParams
  }

  // 提交表单
  const submit = (values) => {
    setStatus(statusType['loading'])
    getAWSEn({ ...values, ...params })
      .then((res) => {
        const {
          data: { code },
        } = res

        if (code === 500) {
          setStatus(statusType['repetition'])
        } else {
          setStatus(statusType['successfully'])
        }
      })
      .catch((err) => {
        console.error(err)
        setStatus(statusType['error'])
      })
  }
  // 表单状态
  const StatusChange = (status: statusType) => {
    switch (status) {
      // 初始化
      case statusType.init:
        partnersForm.resetFields()
        break
      // 提交中
      case statusType.loading:
        return (
          <Modal isOpen>
            <Spin size="large" tip="Submitting..." className={styles.spin} />
          </Modal>
        )
      // 成功
      case statusType.successfully:
        partnersForm.resetFields()
        message.success({
          content: 'Submitted successfully. We will contact you as soon as possible!',
          className: styles.message,
        })
        break
      // 失败
      case statusType.error:
        message.error({ content: ' Submission failed. Please refresh and try again!', className: styles.message })
        break
      // 重复提交
      case statusType.repetition:
        message.error({ content: 'Submission failed due to duplicate form submission.', className: styles.message })
        break
    }
  }
  const statusCom = useMemo(() => {
    return StatusChange(status)
  }, [status])
  return (
    <Layout header={<></>} showBackTop={false} showPhoneCall={false} hideFooter showFloat={false}>
      <Seo {...seo_en} saTitle="Contact Form | Sensors Data" />
      <main className={classnames(styles.root, styles.main)}>
        <div className={styles.container}>
          {/* header_logo */}
          <div className="lg:h-[60px] hidden lg:block">
            <img
              className="h-full"
              src={header_en?.img_url?.publicURL || header_en?.img_url}
              alt={header_en?.img_alt}
            />
          </div>
          {/* 主体表单 */}
          <div className="lg:flex lg:justify-between lg:mt-[70px]">
            <div className="lg:w-[550px] h-[675px]  lg:block hidden">
              <img className="w-full h-full" src={left_aside_en?.left_img?.publicURL} alt={left_aside_en?.img_alt} />
            </div>
            <div className="lg:w-[434px] bg-[#fff] px-[2rem] lg:px-[40px] pb-[22px] pt-[44px] rounded-[4px]">
              <PartnersForm form={partnersForm} onSubmit={submit} status={status} />
            </div>
          </div>
        </div>
      </main>
      {statusCom}
    </Layout>
  )
}

export default partnersFormEN

export const query = graphql`
  query {
    partnersFormYaml {
      seo_en {
        title
        keywords
        description
      }
      header_en {
        img_url {
          publicURL
        }
        img_alt
      }
      left_aside_en {
        left_img {
          publicURL
        }
        img_alt
      }
    }
  }
`
