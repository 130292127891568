import React, { useState } from 'react'
import { Form, Input, Button, Checkbox, Modal } from 'sensd'
import * as styles from './index.module.less'
import classnames from 'classnames'

const PartnersForm = ({ onSubmit, form, status }) => {
  const [policy, setPolicy] = useState({
    url: '',
    title: '',
  })
  const [visible, setVisible] = useState(false)
  const onFinish = ({ protocol, ...values }: any) => {
    // 提交表单
    onSubmit?.(values)
  }
  // 校验失败
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }
  // 协议授权
  const handleClickPolicy = (link: string) => {
    return (e) => {
      e?.preventDefault()
      window.open(link, '_blank')
    }
  }
  const handleOpenPolicy = (link: string, title: string) => {
    return (e) => {
      e?.preventDefault()
      setPolicy({
        url: link,
        title: title,
      })
      setVisible(true)
    }
  }
  const hideModal = () => {
    setVisible(false)
  }

  return (
    <div>
      <Modal
        title={policy.title}
        visible={visible}
        size="large"
        onOk={hideModal}
        onCancel={hideModal}
        okText="OK"
        className="bg-[#F5FAFA]"
        cancelText
      >
        <div className="w-full  h-[480px]">
          <iframe className="w-full h-full overflow-y-scroll" title="readme" src={policy.url} />
        </div>
      </Modal>
      <div className={classnames('text-[#04cb94] font-semibold text-[18px] mb-[20px] ', styles.icon)}>
        Welcome to contact Sensors Data!
      </div>
      <Form form={form} name="partnersForm" onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Form.Item
          name="name"
          rules={[{ required: true, message: 'This field is required.' }]}
          validateTrigger="onBlur"
          hideErrorWhenChange
        >
          <Input name="name" placeholder="name *" />
        </Form.Item>
        <Form.Item
          name="company"
          rules={[{ required: true, message: 'This field is required.' }]}
          validateTrigger="onBlur"
          hideErrorWhenChange
        >
          <Input name="company" placeholder="company *" />
        </Form.Item>
        <Form.Item
          name="department"
          rules={[{ required: true, message: 'This field is required.' }]}
          validateTrigger="onBlur"
          hideErrorWhenChange
        >
          <Input name="position" placeholder="position *" />
        </Form.Item>
        <Form.Item
          name="phone"
          //   rules={[
          //     { required: true, message: '未填写' },
          //     {
          //       pattern: /^1\d{10}$/, // 以1开头，后面跟10位数字
          //       message: '请输入正确手机号',
          //     },
          //   ]}
          validateTrigger="onBlur"
          hideErrorWhenChange
        >
          <Input name="phone" placeholder="phone" />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[
            {
              type: 'email',
              message: 'Please enter a valid email address.',
            },
            { required: true, message: 'This field is required.' },
          ]}
          validateTrigger="onBlur"
          hideErrorWhenChange
        >
          <Input name="email" placeholder="email *" />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: 'This field is required.' }]}
          name="contactPurpose"
          validateTrigger="onBlur"
          hideErrorWhenChange
        >
          <Input.TextArea rows={4} placeholder="Purpose of Contact *" />
        </Form.Item>
        <Form.Item
          name="protocol"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) => (value ? Promise.resolve() : Promise.reject('This field is required.')),
            },
          ]}
        >
          <Checkbox>
            <span className="text-[14px] ">
              I agree to Sensors Data's&nbsp;
              <span
                onClick={handleOpenPolicy(
                  'https://www.sensorsdata.com/compliance/privacy/',
                  'Sensors Data Privacy Policy',
                )}
                className="text-[#04cb94]"
              >
                Privacy Policy
              </span>
            </span>
          </Checkbox>
        </Form.Item>
        <div className="mt-[4rem] lg:mt-[58px]">
          <Form.Item>
            <Button
              form="partnersForm"
              size="large"
              className="w-full bg-[#04CB94]"
              type="primary"
              htmlType="submit"
              disabled={status === 'loading'}
            >
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}

export default PartnersForm
